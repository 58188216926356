import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosHeaders } from 'axios';
import store from '../store/index';
import { setTokens } from '../store/slices/authSlice';
export interface APIOptions {
    token?: string;
    config?: AxiosRequestConfig;
    contentType? : 'application/json' | 'multipart/form-data';
}

export interface CustomHttpResponse {
    data: any;
    status: number;
    headers: any;
}

class APIClient {
    private baseURL: string;
    private retryCount: number = 0;
    
    constructor(baseURL: string) {
        this.baseURL = baseURL;
        axios.defaults.baseURL = baseURL;
    }

    public async get<T>(endpoint: string, options?: APIOptions): Promise<CustomHttpResponse> {
        try {
            const response = await axios.get<T>(this.baseURL + endpoint, this.configureOptions(options));
            return this.handleResponse(response);
        } catch (error: any) {
            await this.handleError(error);
            try {
                const response = await axios.get<T>(this.baseURL + endpoint, this.configureOptions(options));
                this.retryCount = 0;
                return this.handleResponse(response);
            } catch (error: any) {
                return this.handleError(error);
            }
        }
    }

    public async post<T>(endpoint: string, data?: any, options?: APIOptions): Promise<CustomHttpResponse> {
        try {
            const response = await axios.post<T>(this.baseURL + endpoint, data, this.configureOptions(options));
            return this.handleResponse(response);
        } catch (error: any) {
            await this.handleError(error);
            try {
                const response = await axios.post<T>(this.baseURL + endpoint, data, this.configureOptions(options));
                this.retryCount = 0;
                return this.handleResponse(response);
            } catch (error: any) {
                return this.handleError(error);
            }
        }
    }


    public async put<T>(endpoint: string, data?: any, options?: APIOptions): Promise<CustomHttpResponse> {
        try {
            const response = await axios.put<T>(this.baseURL + endpoint, data, this.configureOptions(options));
            return this.handleResponse(response);
        } catch (error: any) {
            await this.handleError(error);
            try {
                const response = await axios.put<T>(this.baseURL + endpoint, data, this.configureOptions(options));
                this.retryCount = 0;
                return this.handleResponse(response);
            } catch (error: any) {
                return this.handleError(error);
            }
        }
    }
    
    public async delete<T>(endpoint: string, options?: APIOptions): Promise<CustomHttpResponse> {
        try {
            const response = await axios.delete<T>(this.baseURL + endpoint, this.configureOptions(options));
            return this.handleResponse(response);
        } catch (error: any) {
            await this.handleError(error);
            try {
                const response = await axios.delete<T>(this.baseURL + endpoint, this.configureOptions(options));
                this.retryCount = 0;
                return this.handleResponse(response);
            } catch (error: any) {
                return this.handleError(error);
            }
        }
    }

    public async patch<T>(endpoint: string, data?: any, options?: APIOptions): Promise<CustomHttpResponse> {
        try {
            const response = await axios.patch<T>(this.baseURL + endpoint, data, this.configureOptions(options));
            return this.handleResponse(response);
        } catch (error: any) {
            await this.handleError(error);
            try {
                const response = await axios.patch<T>(this.baseURL + endpoint, data, this.configureOptions(options));
                this.retryCount = 0;
                return this.handleResponse(response);
            } catch (error: any) {
                return this.handleError(error);
            }
        }
    }

    private configureOptions = (options?: APIOptions) => {
        const headers: AxiosHeaders = new AxiosHeaders();
        headers.setContentType(options?.contentType || 'application/json');
        headers.setAuthorization(`Bearer ${store.getState().auth.accessToken!}`);
        return { ...options?.config, headers};
    };


    private handleResponse<T>(response: AxiosResponse<T>): CustomHttpResponse {
        return {
            data: response.data,
            status: response.status,
            headers: response.headers,
        }
    }

    private async handleError(error: AxiosError): Promise<CustomHttpResponse> {
        if(error.response?.status === 401 && this.retryCount < 1) {
            this.retryCount++;
            await this.refreshAccessTokens();
        }
        throw error;
    }

    private async refreshAccessTokens(): Promise<any> {
        const response = await this.post('/auth/refresh-token', {
            "oldRefreshToken": store.getState().auth.refreshToken
        });
        store.dispatch(setTokens(response.data));
    }
}

export const apiClient = new APIClient('https://api.darb-mea.com/api/v1');

